import { useState, useEffect, useRef } from 'react';
import '../AiChat.css';
import '../App.js';
import DOMPurify from 'dompurify';
import katex from 'katex';
import 'katex/dist/katex.min.css'; // Stellen Sie sicher, dass das CSS von KaTeX geladen wird.
import { getSuggestedQuery } from '@testing-library/react';


function AiChat(props) {
  const [chat, setChat] = useState([]);
  const [text, setText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const chatEndRef = useRef(null);
  const URL = "https://www.songguesser.net:3001";
 // const URL = "http://localhost:3001";


  

  // useEffect, um initializeThread beim Laden der Komponente aufzurufen
  useEffect(() => {
  
      sendMachines();
      
  }, []); 



  async function sendMachines() {
    const currentMyMachines = JSON.stringify(props.overviewData.myMachines);
    let lastMyMachines ="";

     lastMyMachines = localStorage.getItem('lastMyMachines');
    const threadID = localStorage.getItem('threadID');
    let GateInventory = "Thera are no gates in the inventory";
    let Workers = "There are no workers in the inventory.";
    let workersresult = "";
    let failureRate = "";
    let failureRatePrompt ="";

    if (currentMyMachines !== lastMyMachines){
    setIsLoading(true);
    setChat(prevChat => [...prevChat, {you: false, text: "Your process is currently being analysed. You can ask me anything after the analysis.", isLoading: true}]);
    try {
      const level = localStorage.getItem('currentLevel');
      let Gate = "no gate";
      let machinesLevel = props.overviewData.machines

      machinesLevel = machinesLevel.map(step => {
        const { color, ...stepWithoutColor } = step; // Entfernt 'color'
    
        // Filtert Maschinen, die nicht versteckt sind, und entfernt das 'icon' Attribut
        stepWithoutColor.machines = stepWithoutColor.machines
            .filter(machine => !machine.hidden)  // Entfernt Maschinen, wenn 'hidden' true ist
            .map(machine => {
                const { icon, ...machineWithoutIcon } = machine; // Entfernt 'icon'
                return machineWithoutIcon;
            });
    
        return stepWithoutColor;
    });


      if(props.overviewData.hasQualityGate){
        Gate = "the quality gate";
      }
      
      if(props.overviewData.hasReworkGate){
        Gate = "the rework gate";
      }
      
      
      if(level== '"The artisan touch"' || level== '"Gatekeeper of Excellence"'){
        GateInventory = "Additionally, there is a choice to install one Quality Gate (price: $120, method: sorts out bad units, reduces output)  after the step 'Packaging'. ";
        Workers = "One worker can be assignet to each machine to extend the 'failure' interval and reduce the stoppage duration. The following workers are available: "+
                    props.overviewData.workers+" The Attribute 'skill' indicates how effective they are.";
        workersresult = " and workers: $" + props.overviewData.workerCost;
        failureRate = " failure";
        failureRatePrompt ="The 'failure' describes the interval in seconds after which a machine is to fail, causing a stoppage of unknown duration. ";

      }

      if(level== '"Supplier Crises"'|| level == '"Donut Gourmet"'){
        GateInventory = "Additionally, there is a choice to intall one Quality Gate (price: $120, method: sorts out bad units, reduces output) or one Rework Gate " +
        "(price: $120, method: reworks bad units, extends production time by 5 seconds per scrap unit) after the Step 'Packaging'. ";
        Workers = "The following workers are available: "+ props.overviewData.workers+" The Attribute 'skill' indicates how effective they are. "+ 
        "One worker can be assignet to each machine to extend the 'failure' interval and reduce the stoppage duration. ";
        workersresult = " and workers: $" + props.overviewData.workerCost;
        failureRate = " failure";
        failureRatePrompt ="The 'failure' describes the interval in seconds after which a machine is to fail, causing a stoppage of unknown duration. ";
      }


      const playload ={
        threadID: threadID,
        message :
                   "For the level "+level+"  I have a start budget of $"+props.overviewData.budget+" that I can spend on the inventory. "+
                   "The Inventory consists of the following machines. Each machine in the array includes attributes such as name, price,"+ failureRate+ " scrap and duration. " +
                   "The 'price' attribute represents the Acquisition costs. "+
                   "The 'duration' attribute represents the time in seconds each unit spends on the machine, affecting the overall production speed. " +
                   "The 'scrap' indicates that every nth unit produced will be defective, with a lower number indicating higher frequency of defects."+ 
                   "For example, a scrap rate of 9 means every ninth unit is defective. " + failureRatePrompt+ ": "+
                   JSON.stringify(machinesLevel) + Workers + GateInventory +

                   "I have finished my game round. I have configured my production line for this round as follows: " + 
                    JSON.stringify(props.overviewData.myMachines) +
                    ". I installed " + Gate +

                    "Please analyze my results carefully: " +
                    "I achieved a price per unit of $" + props.overviewData.pricePerUnit + ", which should be under the maximum allowed price of $" +
                    props.overviewData.maximumPrice + ". " +
                    "The total production time was: " + props.overviewData.seconds + " seconds, during which I produced " +
                    props.overviewData.goodProducts + " good products from a possible " + props.overviewData.input + ". " +
                    "The total production costs were: $" + props.overviewData.totalCost + ", broken down as follows: " +
                    "Cost of machines : $" + (props.overviewData.machinesAndWorkersCost - props.overviewData.workerCost) +
                    workersresult +
                    ". The cost per production second was $" + props.overviewData.costPerSecond +
                    ", leading to a total time cost of: $" + props.overviewData.timeCost + ". In the end, I delivered " +
                    props.overviewData.badProducts + " defective products, incurring a costs of $" + props.overviewData.costScrap + " each. " +
                    "This evaluation of my machine setup is displayed in an array, which includes the name of the machine (machineName), " +
                    "the downtime (failureTime) and the maximum production backlog (maxValueItem) caused by each machine: " + 
                    props.overviewData.machineData + "." 
      
      };

      

      const response = await fetch(URL + '/ask', {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(playload)
      });
      const data = await response.json();
      if (response.ok) {
        receiveText(data.messages.join('\n')); //Empfänger  
        localStorage.setItem('lastMyMachines', currentMyMachines); 
      } else {
        throw new Error('Network response was not ok. ' + data.message);
      }
    } catch (error) {
        console.error('Error while sending machines:', error);
        receiveText("Error sending machines: " + error.message);
    } finally {
      setIsLoading(false);
       
    }
  }else{
    receiveText("Your process has not changed, but the analysis and our conversation has been saved. You can ask me anything about it.")

  }
  }





  async function sendText() {
    const threadID = localStorage.getItem('threadID');
    setIsLoading(true);
    setChat(prevChat => [...prevChat, {you: true, text: text, isLoading: true}]);
    setText("");
    try {
      const response = await fetch(URL + '/ask', {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({threadID: threadID, message: text})
      });
      const data = await response.json();
      if (response.ok) {
        receiveText(data.messages.join('\n'));
      } else {
        throw new Error('Network response was not ok');
      }
    } catch (error) {
      console.error('Error while fetching data:', error);
      receiveText("Sorry, there was an error processing your request.");
    } finally {
      setIsLoading(false);
    }

    
  }

  function renderMath(text) {
    // Inline math replacement
    text = text.replace(/\\\(.*?\\\)/gs, (match) => {
        const formula = match.slice(2, -2).trim(); // Strip delimiters
        try {
            // Render with inline display mode and modified settings
            return katex.renderToString(formula, {
                throwOnError: false, // Don't throw errors on invalid LaTeX
                displayMode: false, // Inline mode
                maxSize: Infinity, // Allows formula to be as large as needed
                maxExpand: 100, // Limits the number of macro expansions to prevent freeze
                trust: true // Trust all input
            });
        } catch (error) {
            console.error('KaTeX rendering error:', error);
            return match; // Return original on error
        }
    });

    // Display math replacement
    text = text.replace(/\\\[(.*?)\\\]/gs, (match) => {
        const formula = match.slice(2, -2).trim(); // Strip delimiters
        try {
            // Render with display mode and full settings
            return katex.renderToString(formula, {
                throwOnError: false,
                displayMode: true, // Display mode for standalone equations
                maxSize: Infinity,
                maxExpand: 100,
                trust: true
            });
        } catch (error) {
            console.error('KaTeX rendering error:', error);
            return match;
        }
    });

    return text;
}


function receiveText(aitext) {
  const mathRenderedText = renderMath(aitext); // Render math first
  const safeHTML = DOMPurify.sanitize(mathRenderedText); // Then sanitize the HTML

  setChat(prevChat => prevChat.map((item, idx) =>
      idx === prevChat.length - 1 ? { ...item, isLoading: false } : item
  ));
  setChat(prevChat => [...prevChat, { you: false, text: safeHTML }]);
}

  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [chat]);

  function handleKeyPress(event) {
    if (event.key === 'Enter' && text.trim()) {
      sendText();
    }
  }

  return (
    <div className="aichat">
      <div className="chatwindow">
      {chat.map((obj, index) => (
    <div key={index} className={`chatbubble ${obj.you ? "chatbubblemine" : ""}`}>
        <p className="chatbubbletitle">{obj.you ? "You" : "OPEX AI"}</p>
        <div className="chattext" dangerouslySetInnerHTML={{ __html: obj.text }} />
        {obj.isLoading && <div className="spinner"></div>}
    </div>
))}
        <div ref={chatEndRef}></div>
      </div>
     
      <div className="chatinput">
        
        <input
          value={text}
          onChange={e => setText(e.target.value)}
          onKeyPress={handleKeyPress}
          placeholder="What do you want to ask?"
        />
        <div className="chatsendbutton" onClick={() => sendText()}><img src={require("../img/send.svg").default} /></div>
       
      </div>
    </div>
  );
}
export default AiChat;