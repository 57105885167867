/* 
Same as game, but in playground mode:
- No server stuff
- Switch between build and simulate
*/

import './game.css';
import Gametitle from './components/Gametitle.js';
import Medal from './components/Medal.js';
import Teamdots from './components/Teamdots.js';
import Machine from './components/Machine.js';
import StartItem from './components/StartItem.js';
import EndItem from './components/EndItem.js';
import MachineInfo from './components/MachineInfo.js';
import ResultsInfo from './components/ResultsInfo.js';
import Inventory from './components/Inventory.js';
import GameInfoModal from './components/GameInfoModal.js';
import LearningsModal from './components/LearningsModal.js';
import Price from './components/Price.js';
import Finishbutton from './components/Finishbutton.js';
import MachineVisual from "./components/MachineVisual.js";
import SimulationControls from "./components/SimulationControls.js";
import PlaygroundChooseScenarioModal from './components/PlaygroundChooseScenarioModal.js'; 
import StepsnotrightModal from './components/StepsnotrightModal.js';
import FinishGameModal from './components/FinishGameModalNew.js';
import { useEffect, useState, useRef } from 'react';
import Loading from './Loading.js';
import {useSearchParams,useNavigate} from "react-router-dom";

import { game1 } from './chapters.js';

function NiklasSimulation() {

  const [data, setData] = useState({
    case: "Congratulations! You've reached the pinnacle of donut mastery. You’ve got an offer from an investor to make the best donuts in the world. Reduce the reliance on external suppliers, by making the dough in-house. Aim for high-quality standards to drop the rework gate. Unleash your inner donut gourmet. It's time to shine!",
    input: 100,
    setup: [
      {
        color: "#f7b731",
        machines: [
          {
            icon: "🚚",
            name: "Ready-made",
            price: 15,
            scrap: 9,
            failure: 90,
            duration: 3
          },
          {
            icon: "🧑\u200d🍳",
            name: "Home-made",
            price: 25,
            scrap: 60,
            failure: 150,
            duration: 2
          }
        ],
        stepname: "Dough Preparation"
      },
      {
        color: "#eb3b5a",
        machines: [
          {
            icon: "🌡️",
            name: "Air Fryer",
            price: 12,
            scrap: 17,
            failure: 40,
            duration: 4
          },
          {
            icon: "🔥",
            name: "Deep Fryer",
            price: 30,
            scrap: 55,
            failure: 90,
            duration: 3
          }
        ],
        stepname: "Frying"
      },
      {
        color: "#F368E0",
        machines: [
          {
            icon: "🖌️",
            name: "Brush",
            price: 10,
            scrap: 12,
            duration: 7
          },
          {
            icon: "🍫",
            name: "Glazing Machine",
            price: 25,
            scrap: 60,
            failure: 60,
            duration: 4
          }
        ],
        stepname: "Glazing & Topping"
      },
      {
        color: "#2C6E49",
        machines: [
          {
            icon: "✋",
            name: "By Hand",
            price: 12,
            scrap: 60,
            failure: 100,
            duration: 5
          },
          {
            icon: "📦",
            name: "Automatic",
            price: 25,
            scrap: 100,
            failure: 40,
            duration: 3
          }
        ],
        stepname: "Packaging"
      }
    ],
    budget: 999,
    output: 95,
    casetip: "",
    maxprice: 4.6,
    learnings: [
      {
        text: "Is the focus on consistently delivering value to customers through continuous improvement. It means optimizing every aspect of the company's operations to eliminate waste, reduce costs and increase productivity.",
        emoji: "🏆",
        title: "Operational Excellence"
      }
    ],
    videolink: "07-chapter-video.mp4",
    cost_scrap: 20,
    cost_second: 0.6
  });
  const [title, setTitle] = useState(["Title", "Chapter"]);

  const [simulate, setSimulate] = useState(false);
  const [simulationSeconds, setSimulationSeconds] = useState(0);
  const [simulationData, setSimulationData] = useState([]);
  const [simulationData2, setSimulationData2] = useState([]);
  const [speed, setSpeed] = useState(10);
  const [simulationPlaying, setSimulationPlaying] = useState(false);

  const [simulationMachineInfo, setSimulationMachineInfo] = useState([]);

  const [resultsopen, setResultsopen] = useState(false);
  const [results, setResults] = useState({});

  const [inventoryopen, setInventoryopen] = useState(false);
  const [infoopen, setInfoOpen] = useState(false);
  const [gameinfoopen, setGameInfoOpen] = useState(false);
  const [finishgamemodalopen, setFinishgamemodalopen] = useState(false);
  const [learningsmodalopen, setLearningsModalopen] = useState(false);
  //const [choosescenariomodalopen, setChoosescenariomodalopen] = useState(true);
  const [stepsnotrightmodalopen, setStepsnotrightmodalopen] = useState(false);

  const [hasQualityGate, setHasQualityGate] = useState(false);
  const [hasReworkGate, setHasReworkGate] = useState(false);

  const [currentStep, setCurrentStep] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);

  const [building, setBuilding] = useState(false);

  const [price, setPrice] = useState(0);

  //const [mousePosition, setMousePosition] = useState([0,0]);

  const machines = data?.setup;

  const [myMachines, setMyMachines] = useState([[[0,1,0],[0,1]],[[1,1,1],[1,1,0]],[[2,1,0],[2,1,0]],[[3,1,1],[3,0,0]]]);

  const [loading, setLoading] = useState(true);

  const [searchParams] = useSearchParams();

  const navigate = useNavigate();

  const [bestResult, setBestResult] = useState(null);

  let budget = data?.budget;

  // [[[0,0,1]],[[1,0,2],[1,1]],[[2,0,0]]]

  /*
  {
      color: '#ffbe0b',
      machines: [{icon: "🤖",name: "Machine 1", duration: 1, scrap: 70, price: 600, failure: 50},{icon: "💩",name: "Machine 4", duration: 2, scrap: 20, price: 160}]
    },
    {
      color: '#ff006e',
      machines: [{icon: "⏰",name: "Machine 2", duration: 4, scrap: 5, price: 80, failure: 50},{icon: "🔋",name: "Machine 5", duration: 3, scrap: 30, price: 320},{icon: "🛠️",name: "Machine 6", duration: 1, scrap: 9, price: 210}]
    },
    {
      color: '#3a86ff',
      machines: [{icon: "✂️",name: "Machine 3", duration: 4, scrap: 30, price: 280}]
    }
    */

  function deleteMachine(step, index) {
    // Copy into temporary variable
    let temp = [...myMachines];
    // If length less that 1 -> delete whole array
    if(temp[step].length <= 1) {
      temp.splice(step, 1)
    } else {
      // Else, only delete the object inside
      temp[step].splice(index, 1);
    }
    setMyMachines(temp);
  }

  function startBuilding(step, index) {
    setCurrentStep(step);
    setCurrentIndex(index);
    //setMousePosition([0,0]);
    setBuilding(true);
    setInventoryopen(false);
  }

  function addMachine(step, type) {
    let temp = [...myMachines];
    
    if(step == -1) {
      // Add to beginning
      temp.unshift([[currentStep, currentIndex]])
    } else if(type == "new") {
      // Add before or after a machine
      temp.splice(step + 1, 0, [[currentStep, currentIndex]])
    } else if(type == "block") {
      // Add to existing machines (create a gate)
      temp[step].push([currentStep, currentIndex]);
    }
  
    setBuilding(false);
    setMyMachines(temp);
    setInventoryopen(false);
    //console.log(JSON.stringify(temp));
  }

  function addMachinenew(step, index) {
    let temp = [...myMachines];
    
  // Ensure the step exists
    if (temp.length <= step) {
      temp.push([]);
    }

    // Ensure the machines array for the step exists
    if (temp[step].length > 0) {
      temp[step].push([]);
    }

    // Add the machine to the specified step and index
    temp[step][index] = [step, index];
    setMyMachines(temp);
    //console.log(temp);
  }

  function openInfoModal(step, index) {
    if(!simulate) {
      setCurrentStep(step);
      setCurrentIndex(index);
      setInfoOpen(true);
    }
  }

  function getWorkerFixTime(worker) {
    let time = 60;
    switch(worker) {
      case 0:
        time = 30;
        break;
      case 1:
        time = 15;
        break;
      case 2:
        time = 5;
        break;
      default:
        time = 60;
    }
    return time;
  }

  function getWorkerFailureImprovement(worker, currentfailure) {
    // Improve Failure Interval when worker is on machine
    let failurevalue = 1;
    switch(worker) {
      case 0:
        failurevalue = 1.2;
        break;
      case 1:
        failurevalue = 1.6;
        break;
      case 2:
        failurevalue = 2.5;
        break;
      default:
        failurevalue = 1;
    }
    return Math.round(currentfailure * failurevalue);
  }

  function updateBestResult(newresult) {
    if(bestResult === null || newresult < bestResult) {
      setBestResult(newresult);
    }
  }

  function calculateSimulation() {
    const total = data.input;
    let myinput = total;
    let counters = [];
    let simulationdata = [];

    // For done, scrap, etc‚
    let simulationdata2 = [];
    
    let done = 0;
    let scrap = 0;

    let gateindex = 0;

    for(let sec = 0; sec < 10000; sec++) {
      let count = 0;
      let count2 = 0;

      let gatebuffer = 0;
      let gatebufferafter = 0;

      let simulationsecond = [];

      if(sec > 0) {
        if(myinput > 0) {
          myinput--;
          gatebuffer++;
        }
      }
      // Loop through all steps
      myMachines.forEach((step, stepindex) => {

        gatebufferafter = 0;
        let indexcounter = [];
        let bufferlist = [];

        // Get global indexes of the machines inside this step
        step.forEach(item => {
          indexcounter.push(count2);
          count2++;
        })

        // If there are more than one machine in this step, find out which has a smaller workload
        if(gatebuffer > 0 && indexcounter.length > 1) {
          
          // Save buffers in temporary array
          indexcounter.forEach(indexitem => {
            bufferlist.push(simulationdata[sec-1][indexitem][0]);
          })
          // Set gateindex to the machine with the smaller buffer
          gateindex = bufferlist.indexOf(Math.min(...bufferlist));
        } else {
          // If there is only one machine or no buffer to take, set gateindex to 0
          gateindex = 0;
        }

        // Loop through all machines inside the step
        step.forEach((obj, index) => {
          let machine = machines[obj[0]].machines[obj[1]];

          if(sec == 0) {
            // First second, fill the data
            simulationsecond.push([0, false]);
            counters.push({buffer: 0, isFailing: false, duration: 0, scrap: 0, failure: 0, workerfix: 0});
          } else {
            // Is the machine working?
            if(counters[count].isFailing == false) {
              // Is the machine working on a piece at the moment?
              if(counters[count].buffer > 0) {
                counters[count].duration++;
                counters[count].failure++;
                // Is the machine done with the piece?
                if(counters[count].duration == machine.duration) {
                  counters[count].buffer--;
                  counters[count].duration = 0;
                  counters[count].scrap++;
                  // Is the piece of sufficient quality? If yes: pass on to the next buffer. If no: scrap
                  if(counters[count].scrap == machine.scrap) {
                    scrap++;
                    counters[count].scrap = 0;
                  } else {
                    gatebufferafter++;
                  }
                }
              }
              // Machine fails
              if(counters[count].failure == getWorkerFailureImprovement(obj[2], machine.failure)) {
                counters[count].isFailing = true;
                counters[count].workerfix = getWorkerFixTime(obj[2]);
                counters[count].failure = 0;
              }
            } else {
              // Machine is failing. Count down fixing time.
              counters[count].workerfix--;
              // If fixing time is zero, machine will work again
              if(counters[count].workerfix <= 0) {
                counters[count].isFailing = false;
              }
            }
            // Check if this machine should take the load from the buffer
            if(index == gateindex) {
              counters[count].buffer += gatebuffer;
              gatebuffer = 0;
            }
          
            // Push the data to the array
            simulationsecond.push([counters[count].buffer, counters[count].isFailing]);
          }
          count++;
        })
        // Add finished pieces to the buffer of the next step of machines
        gatebuffer += gatebufferafter;
      })
      // End of looping through all steps & machines. Count done pieces and push all data of this second
      done += gatebufferafter;
      simulationdata.push(simulationsecond);

      simulationdata2.push([myinput, done]);

      if((done + scrap) >= total) {
        //console.log("You needed " + sec + " seconds!");
        break;
      }
    }

    //console.log(simulationdata);
    // Save data
    setSimulationData(simulationdata);
    setSimulationData2(simulationdata2);
    if(hasQualityGate) {
      scrap = 0;
    }
    let reworktime = 0;
    if(hasReworkGate) {
      reworktime = scrap * 5;
      scrap = 0;
      done = total;
    }
    setResults({time: simulationdata.length + reworktime, cost: price, input: total, output: done, scrap: scrap, expectedoutput: data.output});
    calculateMachineInfo(simulationdata);
    updateBestResult(getCostPerUnit(price, simulationdata.length + reworktime, scrap, done));
  }


function getCostPerUnit(cost, time, scrap, output) {
    return Math.round((cost + time * data.cost_second + scrap * data.cost_scrap)/output * 100)/100;
}

  function resetSimulation() {
    setSimulationPlaying(false);
    setSimulationSeconds(0);
  }

  function handleTime() {
    setSimulationSeconds(simulationSeconds => simulationSeconds + 1);
  }

  const progressTimer = useRef();

  useEffect(() => {
    if(simulationPlaying) {
        progressTimer.current = setInterval(handleTime, 1000/speed);
        return () => clearInterval(progressTimer.current);
    } 
  }, [simulationPlaying, speed])

  useEffect(() => {
    if (simulationSeconds > simulationData.length) {
      clearInterval(progressTimer.current);
      resetSimulation();
      setResultsopen(true);
    } 
  }, [simulationSeconds]);

  useEffect(() => {
    if (simulate) {
      calculateSimulation();
      resetSimulation();
    } 
  }, [simulate]);

  function calculateMachineInfo(simulationdata) {
    let arr = [];
    simulationdata.forEach(second => {
      second.forEach((obj,index) => {
        if(arr[index] == undefined) {
          arr[index] = [0,0];
        }
        // get new max
        if(obj[0] > arr[index][0]) {
          arr[index][0] = obj[0];
        }
        // increment counter if failure
        if(obj[1] == true) {
          arr[index][1]++;
        }
      })
    });
    setSimulationMachineInfo(arr);
  }

  useEffect(() => {
    getPrice();
  }, [myMachines, hasQualityGate, hasReworkGate]);



  useEffect(() => {
    
         
            setTitle(["Niklas Test", "Level 7"]);
            /*const promptmachines = prompt('Enter your machine setup');
            if(promptmachines != "") {
              setMyMachines(JSON.parse(promptmachines));
            }*/
            setHasQualityGate(true);
            setHasReworkGate(false);
        
              calculateSimulation();
              setSimulate(true);
            
            setLoading(false);
            setResultsopen(true);
}, []);

  function getPrice() {
    let price = 0;
    // Loop over every machine and get the price
    myMachines.forEach(obj => {
      obj.forEach(x => {
        price += machines[x[0]].machines[x[1]].price;
        // Get price of worker
        if(x[2] == 0) {
          price += 5;
        } else  if(x[2] == 1) {
          price += 15;
        } else  if(x[2] == 2) {
          price += 40;
        }
      })
    })
    if(hasQualityGate) {
      price += 120;
    }
    if(hasReworkGate) {
      price += 120;
    }
    setPrice(price);
  }

  function getArrowBefore(amount) {
    if(amount == 1) {
      return <img src={require("./img/arrow1-end.png")} className='arrow1'/>
    } else if(amount == 2) {
      return <img src={require("./img/arrow2-end.png")} className='arrow2before'/>
    } else if(amount == 3) {
      return <img src={require("./img/arrow3-end.png")} className='arrow3before'/>
    }
  }

  function getArrowAfter(amount) {
    if(amount == 1) {
      return <img src={require("./img/arrow1-start.png")} className='arrow1'/>
    } else if(amount == 2) {
      return <img src={require("./img/arrow2-start.png")} className='arrow2after'/>
    } else if(amount == 3) {
      return <img src={require("./img/arrow3-start.png")} className='arrow3after'/>
    }
  }

  function changeWorker(worker) {
    let temp = [...myMachines];
    if(worker >= 0 && worker <= 2) {
      // change Worker
      temp[currentStep][currentIndex][2] = worker;
    } else {
      // remove worker
      temp[currentStep][currentIndex].splice(2, 1);
    }
    setMyMachines(temp);
  }

  /*
  function handleMouseMove(e) {
    if(building) {
      //console.log([e.screenX, e.screenY]);
      setMousePosition([e.screenX, e.screenY])
    }
  }
  */

  function getSimulationData(counter) {
    if(simulationData[simulationSeconds] !== undefined) {
      if(simulationData[simulationSeconds][counter] !== undefined) {
        return simulationData[simulationSeconds][counter];
      } else {
        return [0, false];
      }
    } else {
      return [0, false];
    }
  }

  function getSimulationDataStartEnd(type) {
    if(simulationData2[simulationSeconds] !== undefined) {
        return simulationData2[simulationSeconds][type == "start" ? 0 : 1];
      } else {
        return [0, 0];
      }
  }

  function getBuildingButton() {
    if(simulate) {
      return <SimulationControls playing={simulationPlaying} speed={speed} seconds={simulationSeconds} changeSpeed={(x) => setSpeed(x)} changePlaying={(x) => setSimulationPlaying(x)} changeSeconds={(x) => setSimulationSeconds(x)} reset={() => resetSimulation()} maxseconds={simulationData.length-1}/>;
    } else {
      if(building) {
        return <button className='cancelbutton' onClick={() => setBuilding(false)}><img src={require("./img/cancel.png")}/>Cancel</button>
      } else {
        return <button className='plusbutton' onClick={() => setInventoryopen(true)}><img src={require("./img/plus.png")}/>Build</button>
      }
    }
  }

  function getFinishButton() {
    if(simulate) {
      return <div>
          <button className='donebutton' style={{bottom: 100}} onClick={() => setResultsopen(true)}>🔎 Show Results</button>
          <button className='donebutton' onClick={() => backtobuild()}>🔨 Back to Build</button>
        </div>;
    } else {
      if(budget - price >= 0) {
        return <button className='donebutton' onClick={() => openFinishModal()}>🔮 Simulate</button>;
      } else {
        return <button className='donebutton' style={{opacity: 0.5}} onClick={() => alert("You spent more than your budget!")}>🔮 Simulate</button>;;
      }
    }
  }

  function backtobuild() {
    setResultsopen(false);
    resetSimulation();
    setSimulate(false);
  }

  function checkMachines() {
    let isvalid = true;
    if(myMachines.length != machines.length) {
      isvalid = false;
    }
    myMachines.forEach((step, stepindex) => {
      step.forEach(obj => {
        if(obj[0] != stepindex) {
          isvalid = false;
        }
      })
    })
    return isvalid;
  }

  function openFinishModal() {
    // check if order of the steps is correct
    if(checkMachines()) {
      calculateSimulation();
      setFinishgamemodalopen(true);
      //calculateSimulation();
      //setSimulate(true);
    } else {
      setStepsnotrightmodalopen(true);
      //alert("Looks like your steps don't seem right.. Make sure your steps are in the right order, and that you have at least one machine for each step.")
    }
  }

  function finishGame(text) {
    /*
    setLoading(true);
    console.log(results);
    let unitcost = getCostPerUnit(results.cost, results.time, results.scrap, results.output);
    let uploaddata = { mymachines: myMachines, hasqualitygate: hasQualityGate ? 1 : 0, hasreworkgate: hasReworkGate ? 1 : 0, chapterid: searchParams.get('chapter'), rationale: text, output: results.output, unitprice: unitcost, seconds: results.time, machineinfo: simulationMachineInfo};
    fetch('https://opex-api.simonrahm.pro/v2/newfinishgame.php', {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem("uuid"),
      },
      body: JSON.stringify(uploaddata),
      })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        console.log(data);
          if(data == "fail") {
              alert("Oops, something went wrong. 🤷 Please try again later.")
              setLoading(false);
          } else {
            setFinishgamemodalopen(false);
            setSimulate(true);
            setLoading(false);
          }
  })*/
  setFinishgamemodalopen(false);
            setSimulate(true);
            setLoading(false);
}

function openlearnings() {
  setResultsopen(false);
  setLearningsModalopen(true);
}



  let counter = -1;


if(loading) {
  return (
      <Loading/>
  )
} else {
  return (
    <div className="app" /*onMouseMove={handleMouseMove}*/>

      {/*building ? <div style={{position: 'absolute', left: mousePosition[0] - 42, top: mousePosition[1] - 200, zIndex: 100}}><MachineVisual icon={machines[currentStep].machines[currentIndex].icon} bgcolor={machines[currentStep].color} building={true} /></div> : null */}

      <div className='gradientbg'></div>
      <div onClick={() => navigate("/gameoverview")}><img className='backicon' src={require('./img/icon_back.png')} /></div>
      <Gametitle ontitleclick={() => setGameInfoOpen(true)} title={title[0] + ": " + title[1]} />

      <div style={{color: '#fff', position: 'absolute', top: 24, right: 24, textAlign: 'right'}}>
        <div style={{fontSize: 24, marginBottom: 4}}>🏆 Your goal:</div>
        <div style={{display: 'flex', alignItems: 'center'}}>
          <span style={{fontSize: 12, width: 100, opacity: 0.6, marginRight: 8}}>Maximum unitprice</span>
          <span style={{color: '#71ce62', fontSize: 32}}>$ {data.maxprice}</span> 
        </div>
      </div>      
      <div className='bottombar'>
        <Price price={price} budget={budget} />
        {getBuildingButton()}
        {getFinishButton()}
        {/*<Finishbutton current={myMachines.length} all={machines.length} />*/}
        {/*<button className='donebutton' onClick={() => setSimulate(!simulate)}>{simulate ? "🔨 Build" : "🔮 Simulate"}</button>*/}
      </div>

      {inventoryopen ? <Inventory editor={true} onDismiss={() => setInventoryopen(false)} machines={machines} onAdd={(a,b) => startBuilding(a,b)} onQualityGateClick={() => setHasQualityGate(!hasQualityGate)} qualitygate={hasQualityGate} onReworkGateClick={() => setHasReworkGate(!hasReworkGate)} reworkgate={hasReworkGate} hidequalitygate={data?.hidequalitygate} hidereworkgate={data?.hidereworkgate} /> : null}

      {infoopen ? <MachineInfo editor={true} onDismiss={() => setInfoOpen(false)} onWorkerChange={(x) => changeWorker(x)} machinedata={machines[myMachines[currentStep][currentIndex][0]].machines[myMachines[currentStep][currentIndex][1]]} worker={myMachines[currentStep][currentIndex][2]} bgcolor={machines[myMachines[currentStep][currentIndex][0]].color} hideworkers={data?.hideworkers}/> : null}

      {gameinfoopen ? <GameInfoModal title={title[1]} description={data.case} videolink={data?.videolink} tip={data?.casetip} caseimage={data?.caseimage} onDismiss={() => setGameInfoOpen(false)} /> : null}

      {learningsmodalopen ? <LearningsModal learnings={data?.learnings} onDismiss={() => setLearningsModalopen(false)} goToLeaderboard={() => navigate(`/leaderboard?id=${searchParams.get('chapter')}`)} /> : null}

      {finishgamemodalopen ? <FinishGameModal onfinish={(text) => finishGame(text)} onDismiss={() => setFinishgamemodalopen(false)} /> : null}

      {/*choosescenariomodalopen ? <PlaygroundChooseScenarioModal setScenario={(x, y) => setScenario(x, y)} /> : null*/}

      {resultsopen ? <ResultsInfo info={results} onDismiss={() => setResultsopen(false)} goToLeaderboard={() => navigate("/leaderboard/")} openLearnings={() => openlearnings()} tryagain={() => backtobuild()} simulationmachineinfo={simulationMachineInfo} machines={machines} mymachines={myMachines} bestresult={bestResult} hideleaderboard={false} costsecond={data.cost_second} costscrap={data.cost_scrap} qualitygate={hasQualityGate} maxprice={data?.maxprice} /> : null}

      {stepsnotrightmodalopen ? <StepsnotrightModal onDismiss={() => setStepsnotrightmodalopen(false)} /> : null}

      <div className='playground'>
        <StartItem amount={getSimulationDataStartEnd("start")} simulate={simulate} name={data?.inputname} />
        {building ? <div className='buildplusbutton' onClick={() => addMachine(-1, "new")}><img src={require("./img/plus.png")}/></div> : null }
  {
    myMachines.map((step,stepindex) => {
      // Loop through every step and display items inside
      return (
        <>
        {getArrowBefore(step.length)}
        <div key={stepindex} className={'gatecontainer gatecontainer' + step.length}>
        {step.map((obj, index) => {
          counter++;
          return <Machine key={stepindex+"."+index} editor={true} icon={machines[obj[0]].machines[obj[1]].icon} name={machines[obj[0]].machines[obj[1]].name} simulate={simulate} simulationdata={getSimulationData(counter)} onDelete={() => deleteMachine(stepindex, index)} bgcolor={machines[obj[0]].color} worker={obj[2]} onCardClick={() => openInfoModal(stepindex, index)}/>
        })}
        {building && step.length < 3 ? <div className='buildplusbutton buildplusbuttoncontainer' onClick={() => addMachine(stepindex, "block")}><img src={require("./img/plus.png")}/></div> : null}
        </div>
        {getArrowAfter(step.length)}
        {building ? <div className='buildplusbutton' onClick={() => addMachine(stepindex, "new")}><img src={require("./img/plus.png")}/></div> : null}
        </>
      )
    })
  }
  
        {hasQualityGate ? <div className='qualitygatecontainer'><img src={require("./img/qualitygate.png")} className='qualitygate' />{simulate ? null :<button onClick={() => setHasQualityGate(false)} className="deleteicon"><img src={require("./img/delete.png")}/></button>}</div> : null }
        {hasReworkGate ? <div className='qualitygatecontainer'><img src={require("./img/reworkgate.png")} className='qualitygate' />{simulate ? null :<button onClick={() => setHasReworkGate(false)} className="deleteicon"><img src={require("./img/delete.png")}/></button>}</div> : null }
        <EndItem amount={getSimulationDataStartEnd("end")} simulate={simulate} name={data?.outputname} />
      </div>
      
    </div>
  );
}
}

export default NiklasSimulation;
